import React from "react";

const PricingContent = [
  {
    packName: "FREE",
    price: "0",
    // billCycle: "1 Admin",
    preFeatures: [
      "Unlimited Kaizens",
      "5 users",
      // "Upload files - Images, Pdf",
      "Standard Dashboard",
      "Email Support",
      "Video Tutorials / User Manuals",
    ],
  },
  {
    packName: "Starter",
    price: "2,500",
    // billCycle: "1 Admin",
    preFeatures: [
      "Unlimited Kaizens",
      "25 users",
      // "Upload files Images, Video, Pdf",
      "Standard Dashboard",
      "Email / Chat Support",
      "Video Tutorials / User Manuals",
    ],
  },
  {
    packName: "Standard",
    price: "3500",
    // billCycle: "1 Admin",
    preFeatures: [
      "Unlimited Kaizens",
      "100 users",
      // "Upload files Images, Video, Pdf, Excel ,Doc",
      "Standard Dashboard",
      "Email/ Chat Support",
      "Video Tutorials / User Manuals",
    ],
  },
  {
    packName: "Enhanced",
    price: "15,000",
    // billCycle: "2 Admin",
    preFeatures: [
      "Unlimited Kaizens",
      "1000 users",
      // "Upload files Images, Video, Pdf, Excel ,Doc",
      "Customized Dashboard",
      "Dedicated RM",
      "Video Tutorials / User Manuals",
      "Customizations & Api integrations at one time additional cost",
    ],
  },
  {
    packName: "Enterprise",
    price: "custom",
    // billCycle: "3 Admin",
    preFeatures: [
      "Unlimited Kaizens",
      "1000 + users",
      // "Upload files Images, Video, Pdf, Excel ,Doc",
      "Customized Dashboard",
      "Dedicated RM",
      "Video Tutorials / User Manuals",
      "Customizations & Api integrations at one time additional cost",
    ],
  },
];

const PricingMonthly = () => {
  return (
    <div className="row no-gutters">
      {PricingContent.map((val, i) => (
        <div className="col-lg col-sm-6 pr-bg d-flex" key={i}>
          <div className="pr-table-wrapper">
            <div className="pack-name">{val.packName}</div>
            <div className="price">
              <sup>₹</sup>
              {val.price}
              <div className="price_monthly">per month</div>
              <div className="price_yearly">Paid Monthly</div>
            </div>
            <div className="bill-cycle">{val.billCycle}</div>
            <a href="#" className="theme-btn-three">
              Try Now
            </a>
            <div className="sub-text">{val.subtext}</div>
            <ul className="pr-feature">
              {val.preFeatures.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
          </div>
          {/* /.pr-table-wrapper  */}
        </div>
      ))}
    </div>
  );
};

export default PricingMonthly;
