import logo from './logo.svg';
import './App.css';
import Routes from './router/Route'
import ScrollTop from './components/ScrollTop'

import { BrowserRouter as Router} from "react-router-dom";

function App() {
  return (
    <>
      {/* <ScrollToTop /> */}
      <Router>
        <ScrollTop/>
        <Routes />
      </Router>
      {/* <>
      <ScrollToTop />
      <Routes />
    </> */}
    </>
  );
}

export default App;
