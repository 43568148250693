import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import { Route, Switch, useLocation, Redirect} from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import MainHeader from "../components/Header/MainHeader";
import Homepage from "../components/Homepage/Homepage";
import Footer from "../components/Homepage/Footer";
import BlogMain from "../components/Blogs/BlogsMain";
import Pricing from "../components/Pricing/Pricing";
import Pricing2 from "../components/Pricing/Pricing2";
import TermsAndConditions from "../components/Footer/TermsAndConditions";
import PrivacyPolicy from "../components/Footer/PrivacyPolicy";

const Routes = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  // let location = useLocation()
  return (
    <>
      <Switch>
        <Route path="/" component={MainHeader} />
      </Switch>
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route exact path="/gamifykaizen">
          <Homepage />
        </Route>
        <Route path="/gamifykaizen/blogs">
          <BlogMain />
        </Route>
        <Route path="/gamifykaizen/pricing">
          <Pricing2 />
        </Route>
        <Route path="/gamifykaizen/termsAndConditions">
          <TermsAndConditions />
        </Route>
        <Route path="/gamifykaizen/privacyPolicy">
          <PrivacyPolicy />
        </Route>
        <Route>
          <Redirect to="/404" />
        </Route>
      </Switch>
      <Switch>
        <Route path="/" component={Footer} />
      </Switch>

      {/* </Router> */}
    </>
  );
};

export default Routes;
