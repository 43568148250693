import React from "react";
import { Link } from "react-router-dom";

const Resources = [
  {
    name: "Blog",
    routerPath: "/gamifykaizen/blogs",
  },
  {
    name: "Ebooks",
    routerPath: "/",
  },
  {
    name: "Video",
    routerPath: "/",
  },
  {
    name: "Guide",
    routerPath: "/",
  },
];

const DesktopMenu = () => {
  return (
    <ul className="navbar-nav">
      {/* <li className="nav-item">
        <Link className="nav-link" to="#" >
          Resources
        </Link>
      </li> */}
      {/* <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="/#"
          data-toggle="dropdown"
        >
          Resources
        </a>
        <ul className="dropdown-menu">
          {Resources.map((val, i) => (
            <li key={i}>
              <Link to={val.routerPath} className="dropdown-item">
                {val.name}
              </Link>
            </li>
          ))}
        </ul> */}
      {/* /.dropdown-menu */}
      {/* </li> */}
      {/* End li */}

      <li className="nav-item ">
        <Link className="nav-link " to="/gamifykaizen/pricing">
          Pricing
        </Link>
      </li>

      {/* End li */}
    </ul>
  );
  // End navbar nav mega menu main
};

export default DesktopMenu;
