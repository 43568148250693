import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import Gamify_kaizen_logo from "../../assets/images/icons/Gamify_kaizen_full_logo.webp";
import close from "../../assets/images/icons/close.svg";
import {
  ProSidebar,
  SidebarHeader,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";

const Resources = [
  {
    name: "Blog",
    routerPath: "/blogs",
  },
  {
    name: "Ebooks",
    routerPath: "/",
  },
  {
    name: "Video",
    routerPath: "/",
  },
  {
    name: "Guide",
    routerPath: "/",
  },
];

const MegaMenuMobile = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div className="mega-menu-wrapper">
      <div className="mob-header multi-mob-header">
        <button className="toggler-menu" onClick={handleClick}>
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      {/* End Header */}

      <ProSidebar
        className={click ? "mega-mobile-menu menu-open" : "mega-mobile-menu"}
      >
        <SidebarHeader>
          <div className="logo position-static">
            <Link to="/">
              <img src={Gamify_kaizen_logo} alt="home-demo" />
            </Link>
          </div>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src={close} alt="icon" />
          </div>
          {/* Mobile Menu close icon */}

          {/* End logo */}
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            {/* <SubMenu title="Resources">
              {Resources.map((val, i) => (
                <MenuItem key={i}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </MenuItem>
              ))}
            </SubMenu> */}
            <MenuItem>
              <Link to="/gamifykaizen/pricing">Pricing</Link>
            </MenuItem>
            <MenuItem>
              <a
                href="https://www.gamifykaizen.com/kaizen/apps/baf.php/MOB/B_OS_BAF_LOGIN/SIGN-IN"
                target="_blank "
              >
                Login
              </a>
              {/* <Link to="/login">Login</Link> */}
            </MenuItem>
            <MenuItem>
              <ul className="right-button-group d-flex align-items-center justify-content-center">
                <li>
                  {/* <a href="/#" className="signUp-action">
                    SIGN UP
                  </a> */}
                  <a
                    href="https://www.gamifykaizen.com/kaizen/apps/baf.php/B_OS_BAF_USER_REGISTER/EDIT"
                    className="signUp-action"
                    target="_blank "
                  >
                    Sign up
                  </a>
                </li>
              </ul>
            </MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default MegaMenuMobile;
