import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PricingMonthly from "./PricingMonthly";
import PricingYearly from "./PricingYearly";

export default class Pricing extends Component {
  render() {
    return (
        <div className="pricing-section-four pt-200 md-pt-100" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-xl-10  m-auto">
              <div className="title-style-six text-center">
                <h2>
                  Solo, Agency or Team? We’ve got you <span>covered.</span>
                </h2>
              </div>
              {/* /.title-style-six */}
            </div>
          </div>
        </div>
        {/* End .container */}

        <div>
      <Tabs>
        <TabList className="nav nav-tabs justify-content-center pricing-nav-three">
          <Tab>Monthly</Tab>
          <Tab>Yearly</Tab>
        </TabList>

        <div className="pricing-table-area-four">
          <TabPanel>
            <PricingMonthly />
          </TabPanel>
          {/* End Pricing Month */}
          <TabPanel>
            <PricingYearly />
          </TabPanel>
          {/* End Pricing Year */}
        </div>
      </Tabs>
    </div>
      </div>
    )
  }
}
