import React from "react";
import { Link } from "react-router-dom";
// import useDocumentTitle from "../../../../components/useDocumentTitle";
// import HeaderTwo from "../../../../components/header/HeaderTwo";
// import Social from "../../../../components/social/Social";
// import FaqThree from "../../../../components/faq/FaqThree";
// import CopyRightTwo from "../../../../components/footer/CopyRightTwo";
// import FooterTwo from "../../../../components/footer/FooterTwo";
// import Pricing from "../../../../components/pricing/pricing-one/Pricing";
// import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PricingMonthly from "./Monthly2";
import PricingYearly from "./Yearly2";
import shape64 from "../../assets/images/shape/64.svg";
import shape65 from "../../assets/images/shape/65.svg";

const PricingCustomerSupport = () => {
  //   useDocumentTitle(
  //     "Pricing Customer Support || Deski-Saas & Software React Template"
  //   );

  return (
    <>
      <div className="main-page-wrapper p0">
        {/* <HeaderTwo /> */}
        {/* End Header */}

        {/* =============================================
				Pricing Section Two
			==============================================  */}
        <div className="pricing-section-two pricing-section-two-custom">
          <div className="fancy-hero-four">
            <div className="shapes shape-one"></div>
            <div className="shapes shape-two"></div>
            <div className="shapes shape-three"></div>
            <div className="shapes shape-four"></div>
            <div className="shapes shape-five"></div>
            <div className="shapes shape-six"></div>
            <div className="bg-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-xl-10 col-lg-11 m-auto">
                    <h2>Choose package match your budget</h2>
                  </div>
                  <div className="col-12 m-auto">
                    <p className="font-rubik sub-heading">
                      5 minute installation · Try Team plan features for 14 days
                      · No credit card required
                    </p>
                  </div>
                </div>
                {/* End .row */}
              </div>
              {/* End .container */}
            </div>
            {/*  /.bg-wrapper */}
          </div>
          {/* /.fancy-hero-four */}

          <div className="container">
            <div className="pricing-table-area">
              <div className="tab-content">
                <div>
                  <Tabs>
                    <TabList className="nav nav-tabs justify-content-center pricing-nav-two pricing-nav-two-custom">
                      <Tab>Monthly</Tab>
                      <Tab>Yearly</Tab>
                    </TabList>

                    <div className="tab-content-wrpper">
                      <TabPanel>
                        <PricingMonthly />
                      </TabPanel>
                      <TabPanel>
                        <PricingYearly />
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
              <img src={shape64} alt="shape" className="shapes shape-one" />
              <img src={shape65} alt="shape" className="shapes shape-two" />
            </div>
            {/*  /.pricing-table-area  */}
          </div>
        </div>
        {/* /.pricing-section-two  */}
      </div>
    </>
  );
};

export default PricingCustomerSupport;
