import React, { useState } from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../useDocumentTitle";
import Lottie from "react-lottie";
import * as animationData from "./animation.json";

import Footer from "./Footer";
import icon1 from "../../assets/images/icons/01.png";
import icon2 from "../../assets/images/icons/02.png";
import icon3 from "../../assets/images/icons/03.png";
import icon4 from "../../assets/images/icons/04.png";
import icon5 from "../../assets/images/icons/05.png";
import icon6 from "../../assets/images/icons/06.png";
import icon7 from "../../assets/images/icons/07.png";
import icon8 from "../../assets/images/icons/08.png";
import shape1 from "../../assets/images/shape/1.svg";
import shape2 from "../../assets/images/shape/2.svg";
import shape3 from "../../assets/images/shape/3.svg";
import shape4 from "../../assets/images/shape/4.svg";
import shape5 from "../../assets/images/shape/18.svg";
import shape6 from "../../assets/images/shape/19.svg";
import shape7 from "../../assets/images/shape/20.svg";
import shape8 from "../../assets/images/shape/21.svg";
import linsShape from "../../assets/images/shape/line-shape-2.svg";
import linsShape1 from "../../assets/images/shape/line-shape-1.svg";
// import banner2 from '../../assets/images/banners/Converting_your_simple_ideas.webp'
// import banner1 from '../../assets/images/banners/From_micro_ideas_to_million_savings.webp'
// import banner3 from '../../assets/images/banners/Get_your_creative_ideas_flowing.webp'
// import banner4 from '../../assets/images/banners/What_if_a_simple_idea_can_land_up.webp'
// import banner5 from '../../assets/images/banners/Rewards.webp'
import banner1 from "../../assets/images/banners/kaizen_img2.svg";
import banner2 from "../../assets/images/banners/kaizen_img3.png";
import banner3 from "../../assets/images/banners/kaizen_img4.svg";
import banner4 from "../../assets/images/banners/kaizen_img5.png";
import banner5 from "../../assets/images/banners/kaizen_img6.svg";
import gif from "../../assets/images/banners/Comp 1_1.gif";
// import kaizen_banner_1 from '../../assets/images/banners/kaizen_banner1.webp'
import kaizen_banner_1 from "../../assets/images/banners/kaizen_img1.png";
import kaizen_banner_2 from "../../assets/images/banners/kaizen_banner2.png";
import kaizen_google_play from "../../assets/images/banners/kaizen_google_play.png";
import { PopupModal } from "react-calendly";

const ProjectManagement = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Gamify Kaizen");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const FeatureContent = [
    {
      icon: "20",
      meta: "customer support",
      subTitle: `customer support.`,
      dataDelay: "0",
    },
    {
      icon: "21",
      meta: "FQA’s",
      subTitle: `FQA’s.`,
      dataDelay: "100",
    },
    {
      icon: "22",
      meta: "Articles & Guidelines",
      subTitle: `Articles & Guidelines.`,
      dataDelay: "300",
    },
  ];

  return (
    <>
      <div className="main-page-wrapper">
        {/* <Header /> */}
        {/* End Header */}

        {/* 
     =============================================
			Hero Banner One
		============================================== */}
        {/* <div className="hero-banner-one">
        <div className="hero-upper-container">
          <div className="icon-box-one">
            <img src={icon1} alt="logo" />
          </div>
          <div className="icon-box-two">
            <img src={icon2} alt="logo" />
          </div>
          <div className="icon-box-three">
            <img src={icon3} alt="logo" />
          </div>
          <div className="icon-box-four">
            <img src={icon4} alt="logo" />
          </div>
          <div className="icon-box-five">
            <img src={icon5}alt="logo" />
          </div>
          <div className="icon-box-six">
            <img src={icon6} alt="logo" />
          </div>
          <div className="icon-box-seven">
            <img src={icon7} alt="logo" />
          </div>
          <div className="icon-box-eight">
            <img src={icon8} alt="logo" />
          </div>

          <div className="bubble-one"></div>
          <div className="bubble-two"></div>
          <div className="bubble-three"></div>
          <div className="bubble-four"></div>
          <div className="bubble-five"></div>
          <div className="bubble-six"></div>

          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 m-auto">
                <h1 className="font-rubik hero-heading">
                Implement, manage & scale your <span>business</span>
                </h1>
                <p className="hero-sub-heading">
                Gamify Kaizen is evolutionizing your companies quality impact on continuous improvement tools
                </p>
              </div>
            </div>

            <form
              action="#"
              className="subscription-form"
              onClick={handleSubmit}
            >
              <input type="email" placeholder="Your work email" />
              <button>Try for free</button>
            </form>

            <p className="sing-in-call">
              Already using Gamify Kaizen? <Link to="/login">Sign in.</Link>
            </p>
          </div>
        </div>
      </div> */}

        <div className="hero-banner-two">
          <div className="container">
            <div className="row align-items-start justify-content-between">
              <div className="col-lg-5 order-lg-first">
                <div className="illustration-holder">
                  <img
                    src={kaizen_banner_1}
                    alt="shape"
                    className="illustration_01"
                  />
                  {/* <Lottie options={defaultOptions}
              // height={400}
              // width={500}
              /> */}
                  {/* <img
                src="images/assets/ils-01.1.png"
                alt="shape"
                className="shapes shape-one"
              />
              <img
                src="images/assets/ils-01.2.png"
                alt="shape"
                className="shapes shape-two"
              />
              <img
                src="images/assets/ils-01.3.png"
                alt="shape"
                className="shapes shape-three"
              /> */}
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 order-lg-last">
                <div className="hero-text-wrapper md-mt-50 mt-50">
                  <h1>
                    Unlock collective wisdom of all employees
                    {/* <span>
                  Business
                  <img
                    src={linsShape1}
                    alt="shape"
                    className="cs-screen"
                  />
                </span> */}
                  </h1>
                  <p className="sub-text">
                    Empower employees to be a part of your organization's
                    continuous improvement.
                    <br />
                    <br />
                    Gamify kaizen helps capture ideas and implement, to enable
                    companies increase savings and enable employees earn rewards
                    for innovations.
                  </p>
                  {/* <p className="sub-text">
                  Gamify kaizen helps capture ideas and implement, to enable companies increase savings and enable employees earn rewards for innovations.
              </p> */}
                  {/* <form onClick={handleSubmit}>
                <input type="email" placeholder="Your work email" />
                <button>Try for free</button>
              </form> */}

                  <div className="book-demo-button">
                    <button onClick={() => setIsOpen(true)}>Book Demo</button>
                    <PopupModal
                      open={isOpen}
                      onModalClose={() => setIsOpen(false)}
                      url="https://calendly.com/bizgamsupport_gamifykaizen/30min?back=1&month=2022-12"
                      text="Request Demo"
                      pageSettings={{
                        backgroundColor: "ffffff",
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        primaryColor: "008ed9",
                        textColor: "4d5055",
                      }}
                      rootElement={document.getElementById("root")}
                      //   color="red"
                    />
                    <a href="https://www.gamifykaizen.com/kaizen/apps/baf.php/B_OS_BAF_USER_REGISTER/EDIT">
                      <button>Get Started</button>
                    </a>
                  </div>

                  {/* <ul className="list-item d-flex mt-20">
                <li>Live chat</li>
                <li>Ticketing</li>
                <li>14 days free trial</li>
              </ul> */}
                  {/* <p className="sing-in-call">
              Already using Gamify Kaizen? <Link to="/login">Sign in.</Link>
              </p> */}
                </div>
                {/* /.hero-text-wrapper */}
              </div>
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}

          {/*  /.trusted-companies */}
        </div>

        <div className="hero-banner-two">
          <div className="container-extend">
            <div className="mb-100">
              <img src={kaizen_banner_2} alt="gif" />
              <div className="container-comp">
                <p>
                  A simplified app for Kaizanees (employees) to showcase the
                  problems and smart solutions to the Organization for a better
                  change.
                </p>
                <img src={kaizen_google_play} alt="gif" />
              </div>
            </div>
          </div>
        </div>

        <div className="fancy-feature-two">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-9 m-auto">
                <div className="title-style-one text-center mb-100 md-mb-100">
                  <h2>Know everything about Gamify Kaizen</h2>
                </div>
                {/* /.title-style-one */}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row align-items-center block-style-two pb-100 md-pb-90">
              <div className="col-lg-6 col-md-8 m-auto">
                <div data-aos="fade-right" data-aos-duration="1200">
                  <div className="img-holder img-holder-one">
                    <div
                      className="cs-screen progress-line-one"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                    ></div>
                    <div
                      className="cs-screen progress-line-two"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                    ></div>
                    <img src={banner1} alt="feature" />
                    <img
                      src={shape1}
                      alt="feature"
                      className="cs-screen screen-one"
                    />
                    {/* <img
                    src={shape2}
                    alt="feature"
                    className="cs-screen dot-shape"
                  /> */}
                    <div className="bubble-one"></div>
                    <div className="bubble-two"></div>
                    <div className="bubble-three"></div>
                    <div className="bubble-four"></div>
                  </div>
                  {/* /.img-holder */}
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="text-wrapper"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  {/* <h6 className="font-rubik" style={{ color: "#FF5291" }}>
                  FLEXIBILITY
                </h6> */}
                  <h3 className="font-gilroy-bold">
                    From micro ideas to million $ savings
                  </h3>
                  <p>Never stop exploring innovative ideas!!</p>
                  <p>
                    Get all your brainstorming ideas collectively to one
                    simplified App, gamify kaizen.
                    <br />
                    <br />
                    From identifying a problem to standardizing the solution for
                    continuous improvement.
                  </p>
                  {/* <a href="#">Learn about flexibility</a> */}
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>

            <div className="row align-items-center block-style-two pb-100 md-pb-90">
              <div className="col-lg-6 col-md-8 m-auto order-lg-last">
                <div data-aos="fade-left" data-aos-duration="1200">
                  <div className="img-holder img-holder-two">
                    <img src={banner2} alt="feature" />
                    {/* <img
                    src={banner2}
                    alt="feature"
                    className="cs-screen screen-one"
                  /> */}
                    <img
                      src={shape3}
                      alt="feature"
                      className="cs-screen screen-two"
                    />
                    {/* <img
                    src={shape4}
                    alt="feature"
                    className="cs-screen dot-shape"
                  /> */}
                    <div className="bubble-one"></div>
                    <div className="bubble-two"></div>
                    <div className="bubble-three"></div>
                    <div className="bubble-four"></div>
                  </div>
                  {/* /.img-holder */}
                </div>
              </div>
              <div className="col-lg-6 order-lg-first">
                <div
                  className="text-wrapper pl-5 pr-5"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  {/* <h6 className="font-rubik" style={{ color: "#AA4CFF" }}>
                  QUICK & FAST
                </h6> */}
                  <h3 className="font-gilroy-bold">
                    Ideas shortlisted on merit & priority
                  </h3>
                  <p>
                    Gamify kaizen will provide you a simple & faster way to
                    manage and prioritize ideas based on parameters like,
                    savings, safety, security, business impact etc.,
                  </p>
                  {/* <div className="quote">
                  <blockquote>
                    “Our team really feel great to use the deski app specially
                    their faster load & server speed”
                  </blockquote>
                  <div className="d-flex align-items-center">
                    <img src="images/media/img_01.png" alt="media" />
                    <div className="info-meta">
                      <h5>Micle Duke, Product Manager</h5>
                      <span className="font-rubik">Uber Inc.</span>
                    </div>
                  </div>
                </div> */}
                  {/*  /.quote */}
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>

            <div className="row align-items-center block-style-two pb-100 md-pb-90">
              <div className="col-lg-6 col-md-8 m-auto">
                <div data-aos="fade-right" data-aos-duration="1200">
                  <div className="img-holder img-holder-one">
                    <div
                      className="cs-screen progress-line-one"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                    ></div>
                    <div
                      className="cs-screen progress-line-two"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                    ></div>
                    <img src={banner3} alt="feature" />
                    <img
                      src={shape1}
                      alt="feature"
                      className="cs-screen screen-one"
                    />
                    {/* <img
                    src={shape2}
                    alt="feature"
                    className="cs-screen dot-shape"
                  /> */}
                    <div className="bubble-one"></div>
                    <div className="bubble-two"></div>
                    <div className="bubble-three"></div>
                    <div className="bubble-four"></div>
                  </div>
                  {/* /.img-holder */}
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="text-wrapper"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  {/* <h6 className="font-rubik" style={{ color: "#FF5291" }}>
                  FLEXIBILITY
                </h6> */}
                  <h3 className="font-gilroy-bold">Implement ideas</h3>
                  <p>
                    Gamify Kaizen platform facilitates cross-functional
                    collaboration to implement ideas successfully and track the
                    responsible team.
                  </p>
                  {/* <a href="#">Learn about flexibility</a> */}
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>

            <div className="row align-items-center block-style-two pb-100 md-pb-90">
              <div className="col-lg-6 col-md-8 m-auto order-lg-last">
                <div data-aos="fade-left" data-aos-duration="1200">
                  <div className="img-holder img-holder-two">
                    <img src={banner4} alt="feature" />
                    {/* <img
                    src="images/assets/feature-img-06-01.png"
                    alt="feature"
                    className="cs-screen screen-one"
                  /> */}
                    <img
                      src={shape3}
                      alt="feature"
                      className="cs-screen screen-two"
                    />
                    {/* <img
                    src={shape4}
                    alt="feature"
                    className="cs-screen dot-shape"
                  /> */}
                    <div className="bubble-one"></div>
                    <div className="bubble-two"></div>
                    <div className="bubble-three"></div>
                    <div className="bubble-four"></div>
                  </div>
                  {/* /.img-holder */}
                </div>
              </div>
              <div className="col-lg-6 order-lg-first">
                <div
                  className="text-wrapper pl-5 pr-5"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  {/* <h6 className="font-rubik" style={{ color: "#AA4CFF" }}>
                  QUICK & FAST
                </h6> */}
                  <h3 className="font-gilroy-bold">Start Saving</h3>
                  <p>
                    Gamify Kaizen helps to achieve bigger results and gain
                    savings out of micro ideas accumulated over time.
                    <br />
                    <br />
                    430,000 Kaizens implemented per year in Japan.Toyota pays
                    500 yen to the employee for each Kaizen idea, which is
                    currently $5 in US dollars.
                  </p>
                  <p className="source-link">
                    {" "}
                    Source:{" "}
                    <a>
                      {" "}
                      https://www.leanblog.org/2014/11/our-toyota-tour-guides-kaizen/{" "}
                    </a>{" "}
                  </p>
                  {/* <div className="quote">
                  <blockquote>
                    “Our team really feel great to use the deski app specially
                    their faster load & server speed”
                  </blockquote>
                  <div className="d-flex align-items-center">
                    <img src="images/media/img_01.png" alt="media" />
                    <div className="info-meta">
                      <h5>Micle Duke, Product Manager</h5>
                      <span className="font-rubik">Uber Inc.</span>
                    </div>
                  </div>
                </div> */}
                  {/*  /.quote */}
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>

            <div className="row align-items-center block-style-two pb-100 md-pb-90">
              <div className="col-lg-6 col-md-8 m-auto">
                <div data-aos="fade-right" data-aos-duration="1200">
                  <div className="img-holder img-holder-one">
                    <div
                      className="cs-screen progress-line-one"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                    ></div>
                    <div
                      className="cs-screen progress-line-two"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                    ></div>
                    <img src={banner5} alt="feature" />
                    <img
                      src={shape1}
                      alt="feature"
                      className="cs-screen screen-one"
                    />
                    {/* <img
                    src={shape2}
                    alt="feature"
                    className="cs-screen dot-shape"
                  /> */}
                    <div className="bubble-one"></div>
                    <div className="bubble-two"></div>
                    <div className="bubble-three"></div>
                    <div className="bubble-four"></div>
                  </div>
                  {/* /.img-holder */}
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="text-wrapper"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  {/* <h6 className="font-rubik" style={{ color: "#FF5291" }}>
                  FLEXIBILITY
                </h6> */}
                  <h3 className="font-gilroy-bold">Appreciate & Rewards</h3>
                  <p>
                    Smart dashboard will provide the management to identify the
                    top player and boost up their innovation.
                    <br />
                    <br />
                    Don’t play a part,
                    <br />
                    BE A CHANGE, GROW & MAKE BIGGER DIFFERENCES.
                  </p>
                  {/* <a href="#">Learn about flexibility</a> */}
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mb-100" style={{ backgroundColor: "#6f55ff" }}>
          <img
            src={kaizen_banner_2}
            alt="gif"
            style={{ margin: "0 auto", height: "350px" }}
          />
        </div> */}

        <div className="fancy-feature-four mt-20">
          <div className="bg-wrapper">
            <img src={shape5} alt="shape" className="shapes shape-right" />
            <img src={shape6} alt="shape" className="shapes shape-left" />
            <div className="container">
              <div className="title-style-two text-center mb-100 md-mb-50">
                <div className="row">
                  <div className="col-xl-8 col-lg-9 col-md-10 m-auto">
                    {/* <p>What we do</p> */}
                    <h2>
                      {/* Use deski to drive growth at */}
                      <span>
                        Customer Support <img src={linsShape} alt="shape" />
                      </span>
                    </h2>
                  </div>
                  {/* End .col */}
                </div>
              </div>
              {/* End .title */}

              <div className="inner-content">
                <img src={shape7} alt="shape" className="shapes shape-one" />
                <img src={shape8} alt="shape" className="shapes shape-two" />
                <div className="row justify-content-center">
                  {FeatureContent.map((val, i) => (
                    <div
                      className="col-lg-4 col-md-6"
                      key={i}
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay={val.dataDelay}
                    >
                      <div className="block-style-five">
                        <div className="icon">
                          {/* <img src={`images/icon/${val.icon}.svg`} alt="icon" /> */}
                        </div>
                        <h6 className="title">
                          <span>{val.meta}</span>
                        </h6>
                        <p>{val.subTitle}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* End .inner-content */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectManagement;
