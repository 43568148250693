import React from "react";
// import useDocumentTitle from "../../../components/useDocumentTitle";
// import Header from "../../../components/header/Header";
// import BlogStyle1 from "../../../components/blog/BlogStyle1";
// import Footer from "../../../components/footer/Footer";
// import CopyRight from "../../../components/footer/CopyRight";
import useDocumentTitle from "../useDocumentTitle";
import { Link } from "react-router-dom";

const BlogContent = [
  {
    img: "media_01",
    tag: "Technology",
    title: `A Discount Cartridge Is Better Ever.`,
    routePath: "/blog-details",
    dealyAnimation: "",
  },
  {
    img: "media_02",
    tag: "Software",
    title: `Quis Nostr Exercitation Ullamco Laboris nisi ut Aliquip
    exea.`,
    routePath: "/blog-details",
    dealyAnimation: "50",
  },
  {
    img: "media_03",
    tag: "Blog",
    title: `Excepteur sint occaat cupidatat proidet nisi sunt in culpa
    `,
    routePath: "/blog-details",
    dealyAnimation: "100",
  },
  {
    img: "media_07",
    tag: "Technology",
    title: `A Discount Cartridge Is Better Ever.`,
    routePath: "/blog-details",
    dealyAnimation: "50",
  },
  {
    img: "media_08",
    tag: "Software",
    title: `Quis Nostr Exercitation Ullamco Laboris nisi ut Aliquip
    exea.`,
    routePath: "/blog-details",
    dealyAnimation: "100",
  },
  {
    img: "media_09",
    tag: "Blog",
    title: `Excepteur sint occaat cupidatat proidet nisi sunt in culpa`,
    routePath: "/blog-details",
    dealyAnimation: "150",
  },
  {
    img: "media_10",
    tag: "Technology",
    title: `A Discount Cartridge Is Better Ever.`,
    routePath: "/blog-details",
    dealyAnimation: "50",
  },
  {
    img: "media_11",
    tag: "Software",
    title: `Quis Nostr Exercitation Ullamco Laboris nisi ut Aliquip
    exea.`,
    routePath: "/blog-details",
    dealyAnimation: "100",
  },
  {
    img: "media_12",
    tag: "Blog",
    title: `Excepteur sint occaat cupidatat proidet nisi sunt in culpa`,
    routePath: "/blog-details",
    dealyAnimation: "150",
  },
  {
    img: "media_13",
    tag: "Technology",
    title: `A Discount Cartridge Is Better Ever.`,
    routePath: "/blog-details",
    dealyAnimation: "50",
  },
  {
    img: "media_14",
    tag: "Software",
    title: `Quis Nostr Exercitation Ullamco Laboris nisi ut Aliquip
    exea.`,
    routePath: "/blog-details",
    dealyAnimation: "100",
  },
  {
    img: "media_15",
    tag: "Blog",
    title: `Excepteur sint occaat cupidatat proidet nisi sunt in culpa`,
    routePath: "/blog-details",
    dealyAnimation: "150",
  },
];

const BlogMain = () => {
  useDocumentTitle(" Blog V1 || Deski-Saas & Software React Template");
  return (
    <>
      <div className="main-page-wrapper">
        {/* <Header /> */}
        {/* End Header */}

        {/* 	=============================================
            Fancy Hero One
        ==============================================  */}
        <div className="fancy-hero-one">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 m-auto">
                <h2 className="font-gilroy-bold">
                  Check our company inside story
                </h2>
              </div>
              <div className="col-lg-9 m-auto">
                <p className="font-rubik">
                  You will find here our company news and latest update
                </p>
              </div>
            </div>
          </div>
          <div className="bubble-one"></div>
          <div className="bubble-two"></div>
          <div className="bubble-three"></div>
          <div className="bubble-four"></div>
          <div className="bubble-five"></div>
          <div className="bubble-six"></div>
        </div>
        {/* /.fancy-hero-one */}

        {/* =====================================================
            Feature Blog One
        ===================================================== */}
        <div className="feature-blog-one blog-page-bg">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="container">
            <div className="row">
              {BlogContent.map((item, i) => (
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  key={i}
                  data-aos-delay={item.dealyAnimation}
                >
                  <div className="post-meta">
                    <img
                      src={`images/blog/${item.img}.png`}
                      alt="media"
                      className="image-meta"
                    />
                    <div className="tag">{item.tag}</div>
                    <h3>
                      <Link to="/blog-details" className="title">
                        {item.title}
                      </Link>
                    </h3>
                    <Link
                      to={item.routePath}
                      className="read-more d-flex justify-content-between align-items-center"
                    >
                      <span>Read More</span>
                      <i className="flaticon-right-arrow"></i>
                    </Link>
                  </div>
                  {/* /.post-meta */}
                </div>
              ))}
            </div>
            {/* End .row */}
            <div
              className="text-center mt-30 md-mt-10"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="50"
            >
              <a href="#" className="theme-btn-one">
                Show More
              </a>
            </div>
          </div>
        </div>
        {/* /.feature-blog-one */}

        {/* /.theme-footer-one */}
      </div>
    </>
  );
};

export default BlogMain;
