import React from "react";
import { Link } from "react-router-dom";
import Gamify_full_logo from "../../assets/images/icons/Gamify_kaizen_full_logo.webp";

const socialContent = [
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/company/gamify-kaizen/about/",
  },
  {
    icon: "fa-twitter",
    link: "https://twitter.com/gamifykaizen",
  },
  // {
  //   icon: "fa-youtube",
  //   link: "https://www.youtube.com/channel/UCRgvZs5Vc5Ij378mOSgF8Bw",
  // },
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/Gamify-Kaizen-103648962479928",
  },
  // {
  //   icon: "fa-instagram",
  //   link: "https://www.instagram.com/effybuysocial/",
  // },
];

const Footer = () => {
  return (
    <div className="footer-bg-wrapper mt-80">
      <div className="bubble-one"></div>
      <div className="bubble-two"></div>
      <div className="bubble-three"></div>
      <div className="fancy-short-banner-two">
        <div className="container">
          <div className="content-wrapper">
            <div
              className="
                      bg-wrapper
                      d-lg-flex
                      align-items-center
                      justify-content-between
                    "
            >
              <h2 className="font-gilroy-bold">
                Don’t find the answer? contact us for any query.
              </h2>
              <Link to="/"> Contact us</Link>
              <div className="bubble-one"></div>
              <div className="bubble-two"></div>
              <div className="bubble-three"></div>
              <div className="bubble-four"></div>
              <div className="bubble-five"></div>
            </div>
            {/* /.bg-wrapper */}
          </div>
          {/*  /.content-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-two */}

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <div className="row justify-content-end">
              <div
                className="col-lg-3 col-md-12 footer-about-widget"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <a href="index.html" className="logo">
                  <img src={Gamify_full_logo} alt="" />
                </a>
              </div>
              {/* /.about-widget */}
              <div
                className="col-lg-3 col-md-4 footer-list"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="50"
              >
                <h5 className="footer-title">Links</h5>
                <ul>
                  <li>
                    <Link to="/gamifykaizen/">Home</Link>
                  </li>
                  {/* <li>
                    <Link to="/gamifykaizen/">Resources</Link>
                  </li> */}
                  <li>
                    <Link to="/gamifykaizen/">Career</Link>
                  </li>
                </ul>
                <ul className="social-icon d-flex pt-15">
                  {socialContent.map((val, i) => (
                    <li key={i}>
                      <a
                        href={val.link}
                        target="_blank"
                        rel="noreferrer"
                        style={{ fontSize: "22px", marginRight: "10px" }}
                      >
                        <i className={`fab ${val.icon}`}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* /.footer-list */}
              <div
                className="col-lg-3 col-md-4 footer-list"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <h5 className="footer-title">Legal</h5>
                <ul>
                  <li>
                    <Link
                      to="/gamifykaizen/termsAndConditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms & conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/gamifykaizen/privacyPolicy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy policy
                    </Link>
                  </li>
                </ul>
              </div>
              {/* /.footer-list */}
              <div
                className="col-lg-3 col-md-4 address-list"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="150"
              >
                <h5 className="footer-title">Address</h5>
                <ul>
                  <li>
                    <a href="/#">support@bizgam.com</a>
                  </li>
                  <li>
                    <a href="/#" className="mobile-num">
                      +91 7538 851 133
                    </a>
                  </li>
                </ul>
              </div>
              {/* /.footer-list */}
            </div>
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        {/* <div className="container">
                <div className="bottom-footer-content">
                  <CopyRight />
                </div>
              </div> */}
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default Footer;
